var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"testimonials",attrs:{"id":"testimonials"}},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"tw-text-[#1e2022] md:!tw-text-[45px] !tw-text-[25px] md:!tw-leading-[63px] !tw-leading-[43px] md:tw-mb-[62px] tw-mb-[40px] tw-w-[67%]"},[_vm._v(" "+_vm._s(_vm.$t("home.recommendedByLeadingExpertsInTheDigitalWorld"))+" ")]),_c('div',{staticClass:"tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-y-8 tw-gap-x-6"},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4",class:[
            _vm.$i18n.locale == 'en'
              ? 'lg:tw-rounded-bl-none'
              : 'lg:tw-rounded-br-none',
          ]},[_vm._m(0),_c('div',[_c('p',{staticClass:"!tw-text-[#252525] tw-text-base"},[_vm._v(" “"+_vm._s(_vm.$t("home.nawafAlmusaibeehWord"))+"” ")])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute",class:[
              _vm.$i18n.locale == 'en'
                ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
            ]}),_c('div',{staticClass:"tw-flex lg:tw-hidden tw-items-center tw-mt-4"},[_vm._m(1),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.nawafAlmusaibeeh"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.nawafAlmusaibeeh1"))+" ")])])])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-ms-8 tw-items-center"},[_vm._m(2),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.nawafAlmusaibeeh"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.nawafAlmusaibeeh1"))+" ")])])])]),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4",class:[
            _vm.$i18n.locale == 'en'
              ? 'lg:tw-rounded-bl-none'
              : 'lg:tw-rounded-br-none',
          ]},[_vm._m(3),_c('div',[_c('p',{staticClass:"!tw-text-[#252525] tw-text-base"},[_vm._v(" “"+_vm._s(_vm.$t("home.mouniraJamjomWord"))+"” ")])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute",class:[
              _vm.$i18n.locale == 'en'
                ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
            ]}),_c('div',{staticClass:"tw-flex lg:tw-hidden tw-items-center tw-mt-4"},[_vm._m(4),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.mouniraJamjom"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.mouniraJamjom1"))+" ")])])])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-ms-8 tw-items-center"},[_vm._m(5),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.mouniraJamjom"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.mouniraJamjom1"))+" ")])])])]),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4",class:[
            _vm.$i18n.locale == 'en'
              ? 'lg:tw-rounded-bl-none'
              : 'lg:tw-rounded-br-none',
          ]},[_vm._m(6),_c('div',[_c('p',{staticClass:"!tw-text-[#252525] tw-text-base"},[_vm._v(" “"+_vm._s(_vm.$t("home.aliAlfehaidWord"))+"” ")])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute",class:[
              _vm.$i18n.locale == 'en'
                ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
            ]}),_c('div',{staticClass:"tw-flex lg:tw-hidden tw-items-center tw-mt-4"},[_vm._m(7),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.aliAlfehaid"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.aliAlfehaid1"))+" ")])])])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-ms-8 tw-items-center"},[_vm._m(8),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.aliAlfehaid"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.aliAlfehaid1"))+" ")])])])]),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4",class:[
            _vm.$i18n.locale == 'en'
              ? 'lg:tw-rounded-bl-none'
              : 'lg:tw-rounded-br-none',
          ]},[_vm._m(9),_c('div',[_c('p',{staticClass:"!tw-text-[#252525] tw-text-base"},[_vm._v(" “"+_vm._s(_vm.$t("home.mohammedIbrahimWord"))+"” ")])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute",class:[
              _vm.$i18n.locale == 'en'
                ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
            ]}),_c('div',{staticClass:"tw-flex lg:tw-hidden tw-items-center tw-mt-4"},[_vm._m(10),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.mohammedIbrahim1"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.mohammedIbrahim"))+" ")])])])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-ms-8 tw-items-center"},[_vm._m(11),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.mohammedIbrahim1"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.mohammedIbrahim"))+" ")])])])]),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4",class:[
            _vm.$i18n.locale == 'en'
              ? 'lg:tw-rounded-bl-none'
              : 'lg:tw-rounded-br-none',
          ]},[_vm._m(12),_c('div',[_c('p',{staticClass:"!tw-text-[#252525] tw-text-base"},[_vm._v(" “"+_vm._s(_vm.$t("home.sultanAlhaddaWord"))+"” ")])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute",class:[
              _vm.$i18n.locale == 'en'
                ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
            ]}),_c('div',{staticClass:"tw-items-center tw-flex lg:tw-hidden tw-mt-4"},[_vm._m(13),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.sultanAlhadda"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.sultanAlhadda1"))+" ")])])])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-ms-8 tw-items-center"},[_vm._m(14),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.sultanAlhadda"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.sultanAlhadda1"))+" ")])])])]),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4",class:[
            _vm.$i18n.locale == 'en'
              ? 'lg:tw-rounded-bl-none'
              : 'lg:tw-rounded-br-none',
          ]},[_vm._m(15),_c('div',[_c('p',{staticClass:"!tw-text-[#252525] tw-text-base"},[_vm._v(" “"+_vm._s(_vm.$t("home.aliHazmWord"))+"” ")])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute",class:[
              _vm.$i18n.locale == 'en'
                ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
            ]}),_c('div',{staticClass:"tw-items-center tw-flex lg:tw-hidden tw-mt-4"},[_vm._m(16),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.aliHazm"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.aliHazm1"))+" ")])])])]),_c('div',{staticClass:"tw-hidden lg:tw-flex tw-ms-8 tw-items-center"},[_vm._m(17),_c('div',[_c('h3',{staticClass:"tw-text-[#474355] !tw-text-base tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.aliHazm"))+" ")]),_c('h4',{staticClass:"tw-text-primary !tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t("home.aliHazm1"))+" ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-mb-2.5"},[_c('img',{staticClass:"tw-h-[45px] tw-object-cover",attrs:{"src":require("@/assets/images/testimonials/words-cyberME-2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/NawafAlmusaibeeh.94c725a1.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/NawafAlmusaibeeh.94c725a1.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-mb-2.5"},[_c('img',{attrs:{"src":require("@/assets/images/caseStudy/logo-aanaaab.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/testimonials/img-4-2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/testimonials/img-4-2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-mb-2.5"},[_c('img',{staticClass:"tw-h-[45px] tw-object-cover",attrs:{"src":require("@/assets/images/testimonials/words-telgani-2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/ali-fhaid.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/ali-fhaid.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-mb-2.5"},[_c('img',{staticClass:"tw-h-[45px] tw-object-cover",attrs:{"src":require("@/assets/images/testimonials/words-sabbar-ar-2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/image.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/image.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-mb-2.5"},[_c('img',{staticClass:"tw-h-[45px] tw-object-cover",attrs:{"src":require("@/assets/images/testimonials/words-logo-haseel-2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/image-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/image-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-mb-2.5"},[_c('img',{staticClass:"tw-h-[45px] tw-object-cover",attrs:{"src":require("@/assets/images/sapq-logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/image-3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-me-4"},[_c('img',{staticClass:"tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]",attrs:{"src":require("@/assets/images/image-3.png"),"alt":""}})])
}]

export { render, staticRenderFns }