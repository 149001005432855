

<template>
  <div class="investors">
    <div class="container">
      <div class="row align-items-center">
        <div class="mb-3 col-lg-3 col-md-12">
          <h5 class="pe-4 tw-leading-9">{{ $t("home.investors") }}</h5>
        </div>

        <div class="col-lg-9 col-md-12 ps-lg-0 tw-mb-8 lg:tw-mb-0 max-h-100">
          <carousel
            :items="3"
            :loop="true"
            :margin="10"
            :nav="false"
            :dots="false"
            :autoplay="true"
            :speed="1000"
            :autoplayTimeout="1000"
            :autoplayHoverPause="true"
            :responsive="{
              0: {
                items: 2,
              },
              400: {
                items: 3,
              },
              700: {
                items: 3,
              },
              1300: {
                items: 4,
              },
            }"
          >
            <li>
              <img
                src="../../../../assets/images/seedra-Logo.png"
                class="img-fluid"
              />
            </li>
            <li>
              <img
                src="../../../../assets/images/waed.png"
                class="img-fluid"
              />
            </li>
            <li>
              <img
                src="../../../../assets/images/500-logo.png"
                class="img-fluid"
              />
            </li>
            <li>
              <img
                src="../../../../assets/images/nour-nouf.png"
                class="img-fluid"
              />
            </li>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  components: { carousel },
};
</script>

<style lang="scss" scoped>
.investors {
  padding: 30px 0;
  background-image: url('../../../../assets/images/sliders-bg.jpg');
  background-size: cover;

  @media (max-width: 700px){
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  ul {
    gap: 10px;
    justify-content: center;
  }

  h5 {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    background: linear-gradient(to right, #775bbc 0%, #ffc629 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  li {
    background: #fff;
    width: 180px;
    height: 90px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    padding: 35px;
  }

  li img {
    max-height: 60px;
    margin: 0 auto;
  }
}

.max-h-100 {
  max-height: 100px;
}
</style>