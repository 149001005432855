<template>
  <div>
    <div v-if="!isClient() && !isTalent()">
      <GuestLanding />
    </div>
    <div v-if="isClient()">
      <Client />
    </div>
    <div v-if="isTalent()">
      <Talent />
    </div>
  </div>
</template>

<script>
import "../style.css";
import { isClient, isTalent } from "../../utils/shared";
import Talent from "../../pages/Talent/Home/index.vue";
import Client from "../../pages/Client/home/index.vue";
import GuestLanding from "../../pages/landing/landing.vue";
export default {
  components: { Talent, Client, GuestLanding },
  methods: {
    isTalent,
    isClient,
  },
};
</script>

<style lang="scss" scoped></style>