<template>
  <div class="position-relative">
    <div class="row mb-5 talents-card">
      <div
        class="col-md-6 col-lg-4 text-center"
        v-for="(talent, i) in 3"
        :key="i"      >
        <TalentsCardSkelton  :isLoaded="true"/>
      </div>
    </div>
  </div>
</template>

<script>
import TalentsCardSkelton from "../talentCard/talentsCardSkelton.vue";
export default {
  components: { TalentsCardSkelton },
};
</script>

<style lang="scss" scoped></style>
