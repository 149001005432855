<template>
<div>
<div class="landing_wrapper " :class="{ 'landing_wrapper-talent': activeTab == 'talent', 'landing_wrapper-client': activeTab == 'client'  }">
  <div class="intro topIntro nav-banner-wrapper lg:!tw-h-full " :class="{ '!tw-h-[68rem]': activeTab == 'talent', '!tw-h-full': activeTab == 'client'  }">
    <Navbar class="z-index-20" :active-tab="activeTab"/>
    <Banner :talent="activeTab == 'talent'" @toggle="toggle" />
  </div>
  <template v-if="activeTab == 'client'">
    <MeetOurTalents />
    <Investors/>
    <CaseStudies />
    <Podcasts />
    <Supporters/>
    <Benefits/>
    <Testimonials />

    <!-- <Partners/> -->
  </template>
  <template v-else>
    <ServiceSection talents-home />
    <StepsSection />
    <bringBuddy />
  </template>
    <Footer :active-tab="activeTab" />
</div>

</div>
</template>

<script>
// import AOS from "aos";
import Navbar from "./components/navbar/navbar.vue"
import Banner from "./components/banner/banner.vue"

// import Partners from "./components/partners/partners.vue"
import Investors from "./components/investors";
//import Projects from "./components/projects/projects.vue";

import Supporters from "./components/supporters"
import Benefits from "./components/benefits"
import MeetOurTalents from "./components/meetOurTalents"
import Footer from "./components/footer/footer.vue"
import ServiceSection from '../Talent/Home/components/serviceSec/serviceSec.vue';
import StepsSection from '../Talent/Home/components/stepsSec/stepsSec.vue';
import bringBuddy from '../Talent/Home/components/bringBuddy';
import CaseStudies from "./components/caseStudies/";
import Podcasts from "./components/podcasts/";
import Testimonials from './components/testimonials/'
// import "aos/dist/aos.css";
import "./landing.scss"
export default {
  components: {Navbar,Banner, Investors, Supporters, Benefits, MeetOurTalents, Footer, ServiceSection, StepsSection, bringBuddy, CaseStudies, Testimonials, Podcasts },
  data() {
    return {
      activeTab: 'client',
    };
  },
  methods: {
    toggle($event) {
      if($event) {
        this.activeTab = 'talent';
        window.gtag("event", "user type selected", { user_type: 'Talent' })
        if(this.$route.path !== "/home/talent")
        this.$router.push('/home/talent')
      } else {
        this.activeTab = 'client';
        window.gtag("event", "user type selected", { user_type: 'Client' })
        if(this.$route.path != "/home/client")
        this.$router.push('/home/client')
       
      }
      localStorage.setItem("activeUserType", this.activeTab)
    }         
             


  },
  mounted() {
  localStorage.setItem("activeUserType", this.activeTab)
  const { type } = this.$route.params;
  if (['talent', 'client'].includes(type))
    this.activeTab = type;
  else {
    this.activeTab = 'client';
    this.$router.push('/home/client')
  }

},
};

</script>

<style lang="scss" scoped>
.z-index-20 {
  z-index: 20 !important;
}

</style>
