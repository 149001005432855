<template>
  <section class="container" id="case-studies">
    <div
      class="tw-flex tw-items-center tw-justify-center tw-my-16 lg:tw-my-32 tw-h-full md:tw-px-0 tw-bg-no-repeat tw-bg-cover case-study tw-overflow-hidden tw-rounded-3xl"
    >
      <div
        class="tw-flex tw-flex-col-reverse tw-justify-center tw-w-full tw-h-full lg:tw-flex-row"
      >
        <div
          class="tw-flex tw-w-full tw-p-4 lg:tw-py-16 tw-mb-8 lg:tw-mb-0 lg:tw-w-1/2"
        >
          <div
            class="tw-relative tw-flex-row tw-justify-start lg:tw-px-5 tw-px-0 tw-mx-auto lg:tw-flex lg:tw-flex-col lg:tw-top-0 tw-top-2"
          >
            <div class="tw-relative tw-mb-7">
              <img
                src="../../../../assets/images/caseStudy/Ellipse-yellow.svg"
                class="tw-w-12 tw-h-12"
                alt=""
              />
            </div>
            <div
              class="lg:tw-flex tw-h-[140px] lg:tw-h-[230px] tw-min-h-[1em] tw-w-[1px] tw-bg-[#AFABB2] tw-opacity-100 tw-mx-auto"
            />
          </div>
          <div class="tw-flex tw-flex-col tw-justify-center tw-w-full">
            <div
              class="tw-flex tw-justify-center lg:tw-px-4 tw-py-2 tw-px-4 lg:tw-py-3 tw-mx-6 tw-mb-1 tw-bg-white tw-rounded-md tw-shadow-sm lg:tw-mx-0 lg:tw-mb-3 tw-w-max lg:tw-justify-start"
            >
              <img
                src="../../../../assets/images/caseStudy/logo-aanaaab.png"
                alt=""
              />
            </div>
            <h1
              class="tw-text-[#1b1b1b] lg:tw-leading-[56px] tw-leading-10 lg:!tw-text-5xl !tw-text-3xl tw-font-bold lg:tw-mb-3 tw-mb-3 tw-mt-3 lg:tw-mt-0 tw-px-4 lg:tw-px-0 lg:tw-py-3 tw-text-start"
            >
              <span class="tw-text-[#ffc629]">{{ $t('caseStudy.caseStudy') }}</span> {{ $t('caseStudy.with') }} <br />
              
              {{ $t('caseStudy.aanaab') }}
            </h1>
            <p
              class="tw-text-[#00000075] !tw-text-xs lg:!tw-text-xl tw-leading-8 lg:tw-mb-6 tw-mb-3 lg:tw-w-3/4 tw-w-full !tw-text-start tw-px-4 lg:tw-px-0 lg:tw-text-start"
            >
              {{ $t('caseStudy.takeCloserLookAtAanaab') }}
            </p>
            <router-link
              to="/case-study"
              class="tw-text-white tw-mx-4 lg:tw-mx-0 tw-bg-[#422E87] tw-leading-8 tw-font-bold tw-text-xs tw-flex tw-items-center  tw-h-[37.53px] lg:tw-h-auto lg:tw-text-xl tw-w-max tw-py-4 tw-px-7 tw-rounded-full"
            >
              {{ $t('caseStudy.viewCaseStudy') }}
            </router-link>
          </div>
        </div>
        <div class="tw-relative tw-w-full tw-h-auto lg:tw-w-1/2">
          <img
            :src="require($i18n.locale == 'en' ? '@/assets/images/caseStudy/anaab-mockup-2x.png' : '@/assets/images/caseStudy/home/anaab-mockup-ar.png')"
            class="tw-relative tw-bottom-0 tw-object-contain lg:tw-absolute tw-h-4/5 tw-rounded-3xl lg:tw-flex tw-hidden"
            :class="[$i18n.locale == 'en' ? 'tw--right-6' : 'tw--left-0']"
            alt=""
          />
          <img
            class="tw-flex lg:tw-hidden tw-object-contain"
            src="../../../../assets/images/caseStudy/home/mockup-2x.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.case-study {
  background-image: url("../../../../assets/images/caseStudy/bg_case-study-2x.png");
  @media (max-width: 768px) {
    background-position-x: center;
  }
}
</style>
