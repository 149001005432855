<template>
  <section class="testimonials" id="testimonials">
    <div class="container">
      <h1
        class="tw-text-[#1e2022] md:!tw-text-[45px] !tw-text-[25px] md:!tw-leading-[63px] !tw-leading-[43px] md:tw-mb-[62px] tw-mb-[40px] tw-w-[67%]"
      >
        {{ $t("home.recommendedByLeadingExpertsInTheDigitalWorld") }}
      </h1>

      <div
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-y-8 tw-gap-x-6"
      >
      

        <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none'
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-cyberME-2x.png"
                alt=""
              />
            </div>
            <div>
              <p class="!tw-text-[#252525] tw-text-base">
                “{{ $t("home.nawafAlmusaibeehWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/NawafAlmusaibeeh.94c725a1.jpg"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.nawafAlmusaibeeh") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                  {{ $t("home.nawafAlmusaibeeh1") }}
                </h4>
              </div>
            </div>
          </div>

          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/NawafAlmusaibeeh.94c725a1.jpg"
                alt=""
              />
            </div>
            <div>
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.nawafAlmusaibeeh") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                {{ $t("home.nawafAlmusaibeeh1") }}
              </h4>
            </div>
          </div>
        </div>

          <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none'
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img src="@/assets/images/caseStudy/logo-aanaaab.png" alt="" />
            </div>
            <div>
              <p class="!tw-text-[#252525] tw-text-base">
                “{{ $t("home.mouniraJamjomWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/testimonials/img-4-2x.png"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.mouniraJamjom") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                  {{ $t("home.mouniraJamjom1") }}
                </h4>
              </div>
            </div>
          </div>

          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/testimonials/img-4-2x.png"
                alt=""
              />
            </div>
            <div>
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.mouniraJamjom") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                {{ $t("home.mouniraJamjom1") }}
              </h4>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none'
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-telgani-2x.png"
                alt=""
              />
            </div>
            <div>
              <p class="!tw-text-[#252525] tw-text-base">
                “{{ $t("home.aliAlfehaidWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/ali-fhaid.png"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.aliAlfehaid") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                  {{ $t("home.aliAlfehaid1") }}
                </h4>
              </div>
            </div>
          </div>

          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/ali-fhaid.png"
                alt=""
              />
            </div>
            <div>
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.aliAlfehaid") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                {{ $t("home.aliAlfehaid1") }}
              </h4>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none'
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-sabbar-ar-2x.png"
                alt=""
              />
            </div>
            <div>
              <p class="!tw-text-[#252525] tw-text-base">
                “{{ $t("home.mohammedIbrahimWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/image.png"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.mohammedIbrahim1") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                  {{ $t("home.mohammedIbrahim") }}
                </h4>
              </div>
            </div>
          </div>

          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/image.png"
                alt=""
              />
            </div>
            <div>
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.mohammedIbrahim1") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                {{ $t("home.mohammedIbrahim") }}
              </h4>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none'
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-logo-haseel-2x.png"
                alt=""
              />
            </div>
            <div>
              <p class="!tw-text-[#252525] tw-text-base">
                “{{ $t("home.sultanAlhaddaWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-items-center tw-flex lg:tw-hidden tw-mt-4">
              <div class="tw-me-4">
                <img
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/image-2.png"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.sultanAlhadda") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                  {{ $t("home.sultanAlhadda1") }}
                </h4>
              </div>
            </div>
          </div>
          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/image-2.png"
                alt=""
              />
            </div>
            <div>
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.sultanAlhadda") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                {{ $t("home.sultanAlhadda1") }}
              </h4>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none'
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/sapq-logo.png"
                alt=""
              />
            </div>
            <div>
              <p class="!tw-text-[#252525] tw-text-base">
                “{{ $t("home.aliHazmWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-items-center tw-flex lg:tw-hidden tw-mt-4">
              <div class="tw-me-4">
                <img
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/image-3.png"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.aliHazm") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                  {{ $t("home.aliHazm1") }}
                </h4>
              </div>
            </div>
          </div>

          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/image-3.png"
                alt=""
              />
            </div>
            <div>
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.aliHazm") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium">
                {{ $t("home.aliHazm1") }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.world-card {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.03);
}

.testimonials {
  padding: 128px 0;
  background-image: url("../../../../assets/images/client/bg_words-shadow-2x.png");
  min-height: 100vh;
  background-position: center;
  background-size: cover;

  @media (max-width: 700px) {
    padding: 50px 0;
  }
}
</style>
