<template>
  <div
    class="talent-Box talent-card-wrapper"
    :class="{ 'clickable mini': miniProfile }"
    @click="$emit('open-mini-profile', talent.sharable_code)"
  >
    <div class="talent-img">
      <img
        v-if="!talent.image"
        :src="getProfilePlaceholderImage()"
        class="img-fluid"
      />
      <img
        v-if="talent.image && !isLoaded"
        :src="`${baseUrl}${talent.image.path}`"
        class="talent-real-img"
        @error="imageLoadOnError"
      />
    </div>
    <div class="country-flag" v-if="talent.country && !isLoaded">
      <CountryFlag :countryCode="talent.country.code" />
    </div>
    <div class="country-flag height-30" v-if="!talent.country"></div>
    <div class="talent-details">
      <span class="talent-job" :class="{ mini: miniProfile }" v-if="!isLoaded"
        >{{ talent.full_name }} {{ miniProfile ? "" : talent.serial_number }}
        <div class="talent-detail-tooltip">
          <img
            class=""
            :src="
              !talent.unvetted
                ? require('@/assets/images/ic_verifed.svg')
                : require('@/assets/images/ic_unverifed.svg')
            "
          />
          <span
            class="tooltiptext"
            :class="{ 'tooltiptext-unvetted': talent.unvetted }"
          >
            {{
              !talent.unvetted
                ? $t("matchedTalents.verified")
                : $t("matchedTalents.unverifiedTalent")
            }}
          </span>
        </div>
      </span>
      <div
        class="mb-3 d-flex align-items-center justify-content-center"
        v-if="isLoaded"
      >
        <skeleton-loader-vue
          type="rect"
          :width="600"
          :height="30"
          animation="fade"
          rounded
        />
      </div>

      <div
        class="text-center job-exp d-flex justify-content-around"
        v-if="talent.role.id == 3 && !isLoaded"
      >
        <div>
          <h5>{{ talent.BE_lang }}</h5>
          <p class="talent-experience" v-if="talent.experience_years > 0">
            {{ talent.experience_years > 2 ? talent.experience_years :  $i18n.locale == "en"?  talent.experience_years : "" }}
            {{ showExperienceYearsText(talent.experience_years) }}
          </p>
          <p class="talent-experience" v-else>
              {{ $t("matchedTalents.fresh") }}
          </p>
        </div>
        <div>
          <h5>{{ talent.FE_lang }}</h5>
           <p class="talent-experience" v-if="talent.second_experience_years > 0">
            {{ talent.second_experience_years > 2 ? talent.second_experience_years :  $i18n.locale == "en"?  talent.second_experience_years : "" }}
            {{ showExperienceYearsText(talent.second_experience_years) }}
          </p>
          <p class="talent-experience" v-else>
              {{ $t("matchedTalents.fresh") }}
          </p>
        </div>
      </div>
      <div
        class="mb-3 d-flex align-items-center justify-content-center"
        v-if="isLoaded"
      >
        <skeleton-loader-vue
          type="rect"
          :width="600"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
      <div
        class="mb-3 d-flex align-items-center justify-content-center"
        v-if="isLoaded"
      >
        <skeleton-loader-vue
          type="rect"
          :width="600"
          :height="30"
          animation="fade"
          rounded
        />
      </div>

      <p v-if="talent.role.id != 3 && !isLoaded" class="talent-experience">
        <template v-if="talent.experience_years > 0">
                  {{ talent.experience_years > 2 ? talent.experience_years :  $i18n.locale == "en"?  talent.experience_years : "" }}
        {{ showExperienceYearsText(talent.experience_years) }}          
        </template>
        <template v-else>
             {{ $t("matchedTalents.fresh") }}
        </template>

      </p>
      
    </div>

    <div class="talent-skills" v-if="!isLoaded">
      <ul>
        <li v-for="(skill, i) in talent.skills" :key="i">
          <span
            v-if="skill.name"
            v-tooltip="{
              content: skill.name,
              visible: skill.name.length > 10,
            }"
            class="cursor-default"
            >{{ subskills(skill.name) }}</span
          >
        </li>
        <li class="talent-skill-more" v-if="talent.remaining_skills != 0">
          +{{ talent.remaining_skills }}
          {{ miniProfile ? "" : $t("matchedTalents.more") }}
        </li>
      </ul>
    </div>
    <div v-if="!miniProfile" class="mt-2 talent-button d-grid">
      <a
        class="btn btn-primary purple-btn"
        @click="talentProfileNavigator(talent.sharable_code)"
      >
        {{ $t("matchedTalents.viewProfile") }}
      </a>
    </div>
  </div>
</template>

<script>
import CountryFlag from "../../../../../components/shared/countryFalg/countryFalg.vue";
import "./talentCard.scss";
export default {
  props: {
    talent: {
      type: Object,
      required: true,
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
    miniProfile: {
      type: Boolean,
      default: false,
    },
  },
  components: { CountryFlag },
  methods: {
    getProfilePlaceholderImage() {
      if (this.miniProfile)
        return require("@/assets/images/ic_avatar-border.png");
      else return require("@/assets/images/talent-avatar.png");
    },
    imageLoadOnError(e) {
      e.target.src = this.miniProfile
        ? require("@/assets/images/ic_avatar-border.png")
        : require("@/assets/images/talent-avatar.png");
      e.target.style.border = "5px solid transparent";
    },
    talentProfileNavigator(id) {
      const url = this.$router.resolve({ path: `/sq/client/talent-profile/${id}` }).href;
      window.open(url, '_blank');
    },
    subskills(skill) {
      let text = skill;
      if (text.length > 10) {
        text = text.substring(0, 10) + "...";
      }
      return text;
    },
    showExperienceYearsText(years) {
      if (years <= 1) return this.$t("matchedTalents.yearExperience");
      else if (years == 2) return this.$t("matchedTalents.2yearExperiences");
      else if (years >= 3 && years <= 10) return this.$t("matchedTalents.yearExperiences");
      else if (years > 10) return this.$t("matchedTalents.yearExperience");
    }
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.talent-job {
  color: #292929;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 1rem !important;
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 25px;

  &.mini {
    margin-bottom: 0 !important;
  }

  .talent-detail-tooltip {
    top: -4px;
  }

  img {
    margin: 0 5px;
    height: 25px;
    display: flex;
    width: 25px;
  }

  @media (max-width: 768px) {
    line-height: 22px;
    img {
      height: 22px;
      width: 22px;
    }
  }
}

.talent-detail-tooltip {
  position: relative;
}

.talent-detail-tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #000000;
  text-align: center;
  padding: 5px 24px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  opacity: 0;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  transition: opacity 0.3s;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 24px 42px 0 rgb(0 0 0 / 11%), 0 -6px 22px 0 rgb(0 0 0 / 9%);
  display: flex;
  width: max-content;
  left: -30%;
  transform: translate(-50%, 0);
}

.talent-detail-tooltip .tooltiptext-unvetted {
  left: -94%;
}

.talent-detail-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  right: 14%;
  margin-right: 0;
  border-width: 7px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.talent-detail-tooltip > img:hover ~ .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.talent-skills ul {
  /*  display: grid;
  grid-template-columns: repeat(3, 1fr); */
  padding-left: 0;

  .cursor-default {
    cursor: default;
  }
}

.talent-skill-more {
  font-weight: 600;
}

.purple-btn {
  padding: 10px 30px;
}
/* 


.talent-skills li {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.clickable {
  cursor: pointer;
}
</style>
