<template>
  <div class="talent-Box talent-card-wrapper">
    <div class="talent-img">
      
       <skeleton-loader-vue
        class="img-fluid"
        type="circle"
        :width="120"
        :height="120"
        animation="fade"
      />
    </div>
 
    <div class="country-flag height-30">
    </div>
    <div class="talent-details">
      
       <div class="mb-3 d-flex align-items-center justify-content-center"  >
        <skeleton-loader-vue
          type="rect"
          :width="600"
          :height="30"
          animation="fade"
          rounded
        />
      </div>


     <div class="mb-3 d-flex align-items-center justify-content-center"  >
        <skeleton-loader-vue
          type="rect"
          :width="600"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
      <div class="mb-3 d-flex align-items-center justify-content-center"  >
        <skeleton-loader-vue
          type="rect"
          :width="600"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
    </div>
  </div>
</template>

<script>
import "./talentCard.scss";
export default {
  props: ["isLoaded"],
  methods: {
   
  },
  data:()=>{
    return{
       baseUrl: process.env.VUE_APP_AWS_BASE_URL,
    }
  }
};
</script>

<style lang="scss" scoped></style>