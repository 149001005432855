<template>
    <section id="meet-our-talents" class="meetTalents innerPage">
        <div class="container">
            <div class="tw-flex tw-items-center tw-justify-between">
                <h2 class="meetTalents__title">
                    <span>{{ $t('home.meetOur') }}</span>
                    <span>{{ $t('home.talents') }}</span>
                </h2>
                <button class="viewMoreTalents tw-hidden lg:tw-flex" @click="handleRedirection('/client/wizard')">
                    <span>
                        {{ $t('home.viewMoreTalents') }}
                    </span>
                    <svg viewBox="0 0 40 20" width="40px" height="20px">
                        <path fill="#422E87" d="m39.542 8.858-.002-.001L31.376.47a1.528 1.528 0 0 0-2.21.005 1.65 1.65 0 0 0 .005 2.281l5.482 5.631H1.563C.7 8.387 0 9.11 0 10c0 .89.7 1.613 1.562 1.613h33.09l-5.48 5.63a1.65 1.65 0 0 0-.006 2.282 1.528 1.528 0 0 0 2.21.005l8.164-8.387.002-.001c.612-.63.61-1.655 0-2.284Z"/>
                    </svg>
                </button>
            </div>
            <Carousel
                @handleSkillClick="handleRoleClick($event)"
                :skills="roles"
                :isResponse="!isLoading"
            />
            <template v-if="talents && talents.length && !isLoading">
                <div class="mb-5 row talents-card">
                    <div class="text-center col-md-6 col-lg-4" v-for="talent in activeTalents" :key="talent.id">
                        <TalentCard
                            mini-profile
                            :talent="talent"
                            :isLoaded="isLoading"
                            @open-mini-profile="openProfileModal($event)"
                        />
                    </div>
                </div>
            </template>
            
            <talentsSkeletons v-if="isLoading" />
    
            <button v-if="!isLoading" class="viewMoreTalents tw-flex lg:tw-hidden tw-mt-12" @click="handleRedirection('/client/wizard')">
                <span>
                    {{ $t('home.viewMoreTalents') }}
                </span>
                <svg viewBox="0 0 40 20" width="40px" height="20px">
                    <path fill="#422E87" d="m39.542 8.858-.002-.001L31.376.47a1.528 1.528 0 0 0-2.21.005 1.65 1.65 0 0 0 .005 2.281l5.482 5.631H1.563C.7 8.387 0 9.11 0 10c0 .89.7 1.613 1.562 1.613h33.09l-5.48 5.63a1.65 1.65 0 0 0-.006 2.282 1.528 1.528 0 0 0 2.21.005l8.164-8.387.002-.001c.612-.63.61-1.655 0-2.284Z"/>
                </svg>
            </button>
            <div class="profileModal__wrapper">
                <transition name="slide">
                    <div v-if="isOpen" class="profileModal__wrapper__container">
                        <div class="profileModal__wrapper__header">
                            <h5>{{ $t('home.talentProfile') }}</h5>
                            <button class="profileModal__wrapper__close btn-close" @click="isOpen = false"></button>
                        </div>
                        <div class="profileModal__wrapper__profile">
                            <Profile mini :sharable-code="sharableCode"  />
                        </div>
                    </div>
                </transition>
                <div
                    v-if="isOpen"
                    class="blur-wrapper"
                    :class="{ active: isOpen }"
                    @click="isOpen = false"
                >
                </div>
            </div>
        </div>
    </section>

</template>

<script>
import Profile from "@/pages/Client/clientViewTalentProfile/newProfile.vue";
import TalentCard from "@/pages/Client/matching/components/talentCard/talentCard.vue";
import Carousel from "@/pages/Client/matching/components/carousel/carousel.vue";
import talentsSkeletons from "@/pages/Client/matching/components/talentSkeltons/talentSkeltons.vue";

export default {
    components: { Profile, TalentCard, Carousel, talentsSkeletons },
    data() {
        return {
            talents: null,
            isOpen: false,
            isLoading: false,
            sharableCode: null,
            currentRoleId: null,
            roles: [],
        };
    },
    computed: {
        activeTalents() { 
            return this.talents.filter(talent => {
                return talent.role.id === this.currentRoleId;
            });
        },
    },
    watch: {
        isOpen(value) {
            const body = document.querySelector('body');
            if (value)
                body.classList.add('is-fixed');
            else
                body.classList.remove('is-fixed');
        },
    }, 
    async mounted() {
        this.isLoading = true;
        await this.axios
        .get(`/api/staticContent/featuredTalents`)
            .then((res) => {
                this.talents = res.data.data;
                const roleNames = [];
                this.talents.forEach(talent => {
                    if (!roleNames.includes(talent.role.name)) {
                        this.roles.push(talent.role);
                        roleNames.push(talent.role.name);
                    }
                })

                this.currentRoleId = this.roles[0].id;
                this.isLoading = false;
            });
    },
    methods: {
        handleRoleClick(id) {
            this.currentRoleId = id;
        },
        openProfileModal(sharableCode) {
            this.sharableCode = sharableCode;
            this.isOpen = true;
        },
        handleRedirection(path) {
            this.$router.push({ path });
        },
    }
}
</script>

<style lang="scss">

.meetTalents {
    &__title {
        font-size: 45px;
        font-weight: 600!important;
        margin-bottom: 44px;

        span:last-child {
            color: #ffc639;
        }

        @media (max-width: 768px) {
          font-size: 28px;
        }
    }
}

.profileModal__wrapper {
  position: relative;
  &__profile {
    max-width: 650px;
  }
  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 80%;
    max-width: 650px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    transition: transform 0.15s;
    z-index: 100;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 20px 0 0 20px;
    padding: 0 0 30px;
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 27px 20px 18px;
    border-bottom: 1px solid #e2e2e2;

    h5 {
        margin-inline-end: auto;
        font-size: 19px;
        font-weight: 600;
    }
  }

  &__close {
    border: 1px solid #e2e2e2;
    padding: 5px;
    color: #000;
    background-color: transparent;
    font-size: 1.1em;
  }

  > .blur-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .7);
    z-index: 99;
    opacity: 0;
    transition: opacity 1s ease;
  }

  > .blur-wrapper.active {
    opacity: 1;
  }
}


.slide-enter-active {
  transform: translate(0, 0) !important;
  -webkit-transform: translate(0, 0) !important;
  transform: translate(100%, 0) !important;
  -webkit-transform: translate(100%, 0) !important;
}

.slide-leave-active {
  transform: translate(100%, 0) !important;
  -webkit-transform: translate(100%, 0) !important;
}

html[dir="rtl"] {
    .profileModal__wrapper {
        &__container {
            right: auto;
            left: 0;
            border-radius: 0 30px 30px 0;
        }
    }

    .slide-enter-active {
        transform: translate(-100%, 0) !important;
        -webkit-transform: translate(-100%, 0) !important;
    }

    .slide-leave-active {
        transform: translate(-100%, 0) !important;
        -webkit-transform: translate(-100%, 0) !important;
    }
}
.viewMoreTalents {
    align-items: center;
    justify-content: center;
}

.viewMoreTalents span {
    padding-inline-end: 12px;
    font-size: 20px;
    font-weight: 700;
    color: #422e87;
}

html[dir="rtl"] {
    .viewMoreTalents svg {
        transform: rotate(180deg);
    }
}

</style>