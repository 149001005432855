

<template>
  <div class="TrustedByy" :class="{'float': float}">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-12 mb-lg-0">
          <h5 class="pe-4 !tw-text-center lg:tw-text-start tw-text-[#1e2022] ">{{ $t("home.proudlyServed") }}</h5>
        </div>

        <div class="col-lg-9 col-md-12 ps-lg-0 max-h-100">
          <carousel
            :items="3"
            :loop="true"
            :margin="10"
            :nav="false"
            :dots="false"
            :autoplay="true"
            :speed="3000"
            :autoplayTimeout="1000"
            :autoplayHoverPause="true"
            :responsive="{
              0: {
                items: 3,
              },
              700: {
                items: 4,
              },
              1300: {
                items: 7,
              },
            }"
          >
                      <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-27-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-28-2x.png"
                class="img-fluid"
              />
            </li>

             <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-29-2x.png"
                class="img-fluid"
              />
            </li>

             <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-30-2x.png"
                class="img-fluid"
              />
            </li>

              <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-31-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-32-2x.png"
                class="img-fluid"
              />
            </li>

             <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-1-2x.png"
                class="img-fluid"
              />
            </li>

              <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-2-2x.png"
                class="img-fluid"
              />
            </li>

              <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-3-2x.png"
                class="img-fluid"
              />
            </li>

             <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-4-2x.png"
                class="img-fluid"
              />
            </li>

           

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-5-2x.png"
                class="img-fluid"
              />
            </li>


            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-6-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-7-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-8-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-9-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-10-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-11-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-12-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-13-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-14-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-15-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-16-2x.png"
                class="img-fluid"
              />
            </li>

           
            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-18-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-19-2x.png"
                class="img-fluid"
              />
            </li>


            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-20-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-21-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-22-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-23-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-24-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-25-2x.png"
                class="img-fluid"
              />
            </li>

            <li>
              <img
                src="../../../../assets/images/proudlyServed/logo-26-2x.png"
                class="img-fluid"
              />
            </li>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  components: { carousel },
  props: {
    float: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style lang="scss" scoped>
.max-h-100 {
  max-height: 100px;
}
</style>